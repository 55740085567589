<template>
  <div class="main_wrapper">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ $t('portal.request_duplicate_certificate') }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active"><a to="#">{{ $t('portal.request_duplicate_certificate') }}</a></li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="menu_bar_wrapper">
      <b-container>
        <b-row>
          <b-col sm="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('portal.request_duplicate_certificate') }}</h4>
              </template>
              <template v-slot:body>
                <div class="all_service_wrapper venue">
                  <b-overlay :show="load">
                    <!-- <div>
                      <b-alert show dismissible variant="success" v-if="showMessage">
                        {{$t('globalTrans.we_notify_you_in_message')}} <b>&rArr;</b>
                      </b-alert>
                    </div> -->
                    <b-row>
                  <!-- <b-col>
                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail"
                      :org-id="orgiId">
                      <div class="titleDiv">
                      </div>
                    </list-report-head>
                  </b-col> -->
                </b-row>
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12">
                      <b-button variant="primary" @click="pdfExport" class="mb-2 float-right">
                          {{  $t('globalTrans.print') }}
                      </b-button>
                    </b-col>
                  </b-row>
                <b-col lg="12">
                <div id="printData"><b-row>
                    <b-col class="mt-5">
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            <div class="titleDiv">
                              <!-- <span class="text-center text-dark reportTitle" style="font-weight:bold;"></span><br> -->
                            </div>
                        </list-report-head>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{ $t('portal.request_duplicate_certificate') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <div>
                      <p>{{ $t('portal.sd_1') }}</p>
                      <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_2') }}</p>
                      <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_3') }}</p>
                      <p>{{ $t('portal.sd_4') }}</p>
                      <p v-if="$i18n.locale=='en'">{{ 'Subject' + ':' + ' Application for Duplicate Certificate of' + ' ' + getSubjectType(profile.subject_type_id) }}</p>
                      <p v-else>{{ 'বিষয়' + ':' + ' ' + getSubjectType(profile.subject_type_id) + ' -এর ডুপ্লিকেট সার্টিফিকেট -এর জন্য আবেদন' }}</p>
                    </div>
                    <div class="w-100 text-left">
                      <div class="card-inside-title p-1">
                        <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                        style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.applicant_information') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col id="form" lg="12" sm="12">
                        <b-row class="p-3">
                          <table border="0" class="mt-3 tabletwo" style="width:100%;">
                            <tbody>
                              <tr>
                                <td style="width: 18%"  class="text-left pl-3" >{{ $t('elearning_iabm.applicant') }} {{ $t('elearning_iabm.type') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                                <td style="width: 30%" class="text-left">{{ getProfessionType(profile.profession_type) }}</td>
                                <td style="width: 18%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 30%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 18%"  class="text-left pl-3" >{{ $t('elearning_iabm.applicant') }} {{ $t('globalTrans.name') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':'}}</td>
                                <td style="width: 30%" class="text-left">{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name_en }}</td>
                                <td style="width: 18%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 30%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 18%"  class="text-left pl-3" >{{ $t('elearning_iabm.applicant') }} {{ $t('globalTrans.designation') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                                <td style="width: 30%" class="text-left">
                                  <span v-if="profile.profession_type == 1 && profile.designation_id">{{ getDesignation(profile.designation_id) }}</span>
                                  <span v-if="profile.not_here_designation == 1 || profile.profession_type == 2 || profile.profession_type == 3">{{ ($i18n.locale=='bn') ? profile.designation_bn : profile.designation_en }}</span>
                                </td>
                              <td style="width: 18%"  class="text-left pl-3" ></td>
                              <td style="width: 2%"  class="text-left" ></td>
                              <td style="width: 30%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 18%"  class="text-left pl-3" >{{ $t('globalTrans.mobile') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                                <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile, { useGrouping:
                              false }) }}</td>
                                <td style="width: 18%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 30%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 18%"  class="text-left pl-3" >{{ $t('globalTrans.email') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':'}}</td>
                                <td style="width: 30%" class="text-left">{{ profile.email }}</td>
                                <td style="width: 18%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 30%" class="text-left"></td>
                              </tr>
                            </tbody>
                          </table>
                        </b-row>
                        <b-row>
                          <div class="w-100 text-left">
                            <div class="card-inside-title p-1">
                              <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                              style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $i18n.locale === 'bn' ? 'প্রশিক্ষণ/সেমিনার/কর্মশালার তথ্য' : 'Training/Seminer/Workshop Information' }}</h5>
                            </div>
                          </div>
                        </b-row>
                        <b-row class="p-3">
                          <table border="0" class="mt-3 tabletwo" style="width:100%;">
                            <tbody>
                              <tr>
                                <td style="width: 15%"  class="text-left pl-3" >{{ $t('globalTrans.title') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':'}} </td>
                                <td style="width: 25%" class="text-left">{{ ($i18n.locale=='bn') ? profile.training_title_bn : profile.training_title_en }}</td>
                                <td style="width: 15%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 25%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 15%"  class="text-left pl-3" >{{ $t('globalTrans.start_date') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                                <td style="width: 25%" class="text-left">{{ profile.start_date | dateFormat }}</td>
                                <td style="width: 15%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 25%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 15%"  class="text-left pl-3" >{{ $t('globalTrans.end_date') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':'}} </td>
                                <td style="width: 25%" class="text-left">{{ profile.end_date | dateFormat }}</td>
                                <td style="width: 15%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 25%" class="text-left"></td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" class="mt-3"  style="width:100%">
                          <tbody>
                            <tr>
                              <td style="width: 18%"  class="text-left pl-3" >{{ $t('portal.why_need_duplicate_certificate') }}</td>
                              <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                              <td style="width: 50%" class="text-left">
                                <span>{{ ($i18n.locale=='bn') ? profile.reason_bn : profile.reason_en }}</span>
                              </td>
                              <td style="width: 18%"  class="text-left pl-3" ></td>
                              <td style="width: 2%"  class="text-left" ></td>
                              <td style="width: 50%" class="text-left">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" md="12">
                      <table border="0" class="mt-3"  style="width:100%">
                        <tbody>
                          <tr>
                            <td style="width: 7%"  class="text-left pl-3" >{{ $t('elearning_iabm.total_bill') }}</td>
                            <td style="width: 2%"  class="text-left" >{{ ':'}} </td>
                            <td style="width: 30%" class="text-left">{{ $n(profile.fee_amount, { useGrouping: false })}} {{ $t('globalTrans.tk') }}</td>
                          </tr>
                          <tr>
                            <td style="width: 7%"  class="text-left pl-3" >{{ $t('globalTrans.date') }}</td>
                            <td style="width: 2%"  class="text-left" >{{ ':'}}</td>
                            <td style="width: 30%" class="text-left">{{ profile.created_at |dateFormat }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                 </div>
                  <div class="text-right">
                    <b-button type="button" variant="primary" class="mr-2" target="_blank" @click="payment()" :disabled="isLoading">
                      <span>{{ $t('globalTrans.payment') }}</span>
                    </b-button>
                  </div>
                </b-col>
                  </b-overlay>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style>
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 14px!important;
    padding: 4px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
  .alert .close {
    color: red !important;
  }
</style>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '../../../../config/api_config'
import Common from '@/mixins/portal/common'
import ExportPdf from './export_pdf_Preview_details'
import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
import flatpickr from 'flatpickr'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper)
const TWO_MINITES = 60 * 2
const today = new Date().toISOString().substr(0, 10)

export default {
  components: {
    ListReportHead
  },
  mixins: [Common],
  created () {
    this.getPreviewData(this.$route.params.id)
    this.getFeeAmount()
    // this.duplicateCertificateFeeList()
    // this.getVenueCondition()
  },
  data () {
    return {
      current_date: today,
      isLoading: false,
      isLoadingData: false,
      load: false,
      loadModal: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      profile: {},
      officeTypeList: [],
      officeList: [],
      designationList: [],
      reload: false,
      showMessage: false,
      TWO_MINITES: TWO_MINITES
    }
  },
  filters: {
    minutesAndSeconds (value) {
      var minutes = Math.floor(parseInt(value, 10) / 60)
      var seconds = parseInt(value, 10) - minutes * 60
      return `${minutes}:${seconds}`
    }
  },
  computed: {
    professionTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'প্রাইভেট প্রতিষ্ঠান' : 'Private Company',
          text_en: 'Private Company',
          text_bn: 'প্রাইভেট প্রতিষ্ঠান'
        }
      ]
    },
    subjectTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'সেমিনার' : 'Seminer',
          text_en: 'Seminer',
          text_bn: 'সেমিনার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রশিক্ষণ' : 'Training',
          text_en: 'Training',
          text_bn: 'প্রশিক্ষণ'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'কর্মশালা' : 'Workshop',
          text_en: 'Private Company',
          text_bn: 'Workshop'
        }
      ]
    },
    jobTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'ক্যাডার' : 'Cader',
          text_en: 'Cader',
          text_bn: 'ক্যাডার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'নন-ক্যাডার' : 'Non-Cader',
          text_en: 'Non-Cader',
          text_bn: 'নন-ক্যাডার'
        }
      ]
    },
    orgList: function () {
      return this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    duplicateCertificateFeeList: function () {
      return this.$store.state.Portal.trainObj.duplicateCertificateFeeList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          const n = obj.text_bn
          if (typeof n === 'string') {
            const num = n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            return { value: obj.value, text: num }
          } else {
            return { value: obj.value, text: '' }
          }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    govOrPvt: function () {
      const govOrPvt = [
        {
          value: 1,
          text: 'govt',
          text_en: 'govt',
          text_bn: 'সরকারী'
        },
        {
          value: 2,
          text: 'private',
          text_en: 'private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
      const tmpList = govOrPvt.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
      return tmpList
    },
    fiscalYearList: function () {
      return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newVal, oldVal) {
      if (newVal !== 0) {
        this.designationList = this.getDesignationByOrg(newVal)
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'formData.office_type_id': function (newValue) {
      if (newValue !== 0) {
        this.officeList = this.getOfficeList(newValue)
      }
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  methods: {
    getOrgName (id) {
      const trainingType = this.$store.state.Portal.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getSubjectType (id) {
      const trainingType = this.subjectTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getDesignation (id) {
      const trainingType = this.$store.state.Portal.commonObj.designationList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getOrgAddress (id) {
      const trainingType = this.$store.state.Portal.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.address_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.address_en : ''
      }
    },
    getStatus (id) {
      const trainingType = this.statusList.find(job => job.value === parseInt(id))
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
    },
    getJobType (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.jobTypeList.find(job => parseInt(job.value) === parseInt(id))
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
    },
    getApplicationSubject (id) {
      const trainingType = this.subjectTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text : ''
        } else {
          return trainingType !== undefined ? trainingType.text : ''
        }
    },
    getProfessionType (id) {
      const trainingType = this.professionTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text : ''
        } else {
          return trainingType !== undefined ? trainingType.text : ''
        }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getDesignationByOrg (orgId = null) {
      const desigList = this.$store.state.Portal.commonObj.designationList.filter(item => item.status === 0)
      if (desigList) {
          return desigList.filter(item => item.org_id === parseInt(orgId))
      }
      return desigList
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.Portal.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (orgId = null) {
      const officeList = this.$store.state.Portal.commonObj.officeList.filter(item => item.status === 0)
      if (orgId) {
        return officeList.filter(office => office.office_type_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeList
    },
    checkDisable (field) {
      const mainData = []
      const data = mainData[field]
      if (data) {
          return true
      }
      return false
    },
    async getPreviewData (id) {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/duplicate-certificate-request/preview' + '/' + id)
      if (!result.success) {
        this.profile = ''
      } else {
        this.profile = result.data
      }
    },
    async payment () {
      this.load = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, 'external-user/duplicate-certificate-request/payment', this.profile)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.$refs.mymodal.hide()
        this.showMessage = true
        window.location = result.url
        // this.$router.push('/training/certificate-request-preview')
        this.$refs.form.reset()
        this.load = false
        this.otp = ''
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    pdfExport () {
      const reportTitle = this.$t('portal.request_duplicate_certificate')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.profile, this)
    }
    // pdfExport () {
    //   this.$htmlToPaper('printData', this.pageStyle)
    //   // const reportTitle = this.$t('li_step.circular')
    //   // ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 3, reportTitle, this, this.details)
    // }
  }
}
</script>
